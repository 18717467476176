/*
 * @Author: chengyuzhang
 * @Date: 2020-07-03 15:21:33
 * @Last Modified by: ruiwang
 * @Last Modified time: 2024-04-26 17:54:42
 */

export default {
    namespaced: true,

    state: () => ({
        responseData: {},
        serverLoadedFlag: false,
        error: []
    }),

    mutations: {
        set: (state, data) => {
            state.responseData = data;
        },

        setLoadFlag: (state, flag) => {
            state.serverLoadedFlag = flag;
        },

        setError: (state, data) => {
            const errorData = {...data};
            delete errorData.serverResponseTime; // 为增强Etag准确性，去除timestamp影响因素，对业务无影响

            state.error = [
                ...state.error,
                errorData
            ];
        },

        clearError: state => {
            state.error = [];
        }
    }
};
